import { uuid } from '../services/utils'

import { UserCallback } from '../firebase'
import { Recording, RecordingPathE } from 'types/recording'
import { DEV_EXT_ID } from './constants'
import { isLocal } from '../services/environmentService'
import { MixpanelEvent } from './mixpanel'
import { TrackingLinkType } from './trackingLinks'

const callbacks: Record<string, any> = {}

const getMessageKey = (action: string, id: string | null) =>
    id ? `${action}-${id}` : action

chrome.runtime?.onMessage?.addListener(msg => {
    const msgKey = getMessageKey(msg.action, msg.id)

    if (callbacks[msgKey]) {
        callbacks[msgKey](msg.response)

        // Delete executed callbacks only for non-permanent action
        if (msg.id) {
            delete callbacks[msgKey]
        }
    }
})

const noop = () => {}
const sendMessageToBackground = async (
    action: string,
    callback,
    params: any = undefined,
    isPermanent: boolean = false // Permanent action won't have an ID and its callback won't be deleted after execution
) => {
    if (SDK_MODE) {
        const sdkScript = SDK_MODE ? require('./background.sdk') : null // Loads to bundle without this check
        const response = await sdkScript?.default?.({ action, params })
        callback?.(response)
    } else {
        // Message id is required to distinguish callbacks for the same action type in production
        const messageID = isPermanent ? null : uuid()
        const message = { action, params, id: messageID }

        if (isLocal) {
            // When it's a development build (localhost:3000), send message to background via External Messaging
            // and execute callback
            chrome.runtime?.sendMessage(DEV_EXT_ID, message, callback || noop)
        } else {
            // When it's an extension build, send message to background and listen to messages in return
            if (callback) {
                const messageKey = getMessageKey(action, messageID)
                callbacks[messageKey] = callback
            }

            chrome.runtime.sendMessage(message)
        }
    }
}

export const getFirebaseUser = (callback: UserCallback) =>
    sendMessageToBackground('getFirebaseUser', callback, null, true)

export const getPlaybooks = (callback, params) =>
    sendMessageToBackground('getPlaybooks', callback, params)

export const getApps = (callback, params) =>
    sendMessageToBackground('getApps', callback, params)

export const getUserSpaces = (callback, params) =>
    sendMessageToBackground('getUserSpaces', callback, params)

export const getNotifications = (callback, params) =>
    sendMessageToBackground('getNotifications', callback, params, true)

export const markAllNotificationsAsRead = (callback, params) =>
    sendMessageToBackground('markAllNotificationsAsRead', callback, params)

export const removeNotification = (callback, params) =>
    sendMessageToBackground('removeNotification', callback, params)

export const getOrgUsers = (callback, params) =>
    sendMessageToBackground('getOrgUsers', callback, params)

export const sharePlaybook = (callback, params) =>
    sendMessageToBackground('sharePlaybook', callback, params)

export const requestPlaybook = (callback, params) =>
    sendMessageToBackground('requestPlaybook', callback, params)

export const sendFeedback = (callback, params) =>
    sendMessageToBackground('sendFeedback', callback, params)

export const getPlaybook = (callback, params: { playbookId: string }) =>
    sendMessageToBackground('getPlaybook', callback, params)

export const triggerVideoPlayer = (
    callback,
    params: { playbook: any; withDelay: boolean }
) => sendMessageToBackground('triggerVideoPlayer', callback, params)

export const getPlaybookList = (callback, params) =>
    sendMessageToBackground('getPlaybookList', callback, params)

export const getOrganization = (callback, params) =>
    sendMessageToBackground('getOrganization', callback, params)

export const getTranscriptFile = (
    callback,
    params: { transcriptUrl: string }
) => sendMessageToBackground('getTranscriptFile', callback, params)

export const getPbRequestDefaults = callback =>
    sendMessageToBackground('getPbRequestDefaults', callback)

export const logToMixpanel = (event: MixpanelEvent, params = {}) => {
    const tabInfo = {
        'current-app': window._GUIDDE?.CURRENT_APP,
        'current-domain': window._GUIDDE?.CURRENT_DOMAIN,
        'default-tags': window._GUIDDE?.DEFAULT_TAGS,
    }

    sendMessageToBackground('logToMixpanel', null, {
        event,
        params: { ...tabInfo, ...params },
    })
}

export const sendInvite = (callback, params) =>
    sendMessageToBackground('sendInvite', callback, params)

export const getScheduleUrl = (callback, params) =>
    sendMessageToBackground('getScheduleUrl', callback, params)

export const getTagConditions = (callback, params: { domain: string }) =>
    sendMessageToBackground('getTagConditions', callback, params)

export const openExternalLink = (callback, params) =>
    sendMessageToBackground('openExternalLink', callback, params)

export const startScreenCapture = (
    callback,
    params: {
        defaultFilters: any
        link: string
        recordingType?: string
        micId: string
    }
) => sendMessageToBackground('startScreenCapture', callback, params)

export const cancelScreenCapture = (callback, params) =>
    sendMessageToBackground('cancelScreenCapture', callback, params)

export const stopScreenCapture = (callback, params) =>
    sendMessageToBackground('stopScreenCapture', callback, params)

export const getRecordingStatus = (callback, params) =>
    sendMessageToBackground('getRecordingStatus', callback, params, true)

export const getRecordingStartTime = callback =>
    sendMessageToBackground('getRecordingStartTime', callback)

export const deleteRecordings = (
    callback,
    params: {
        path: RecordingPathE
        recordings: Recording[]
    }
) => sendMessageToBackground('deleteRecordings', callback, params)

export const getExtensionId = (callback, params) =>
    sendMessageToBackground('getExtensionId', callback, params)

export const getAvailableDevices = callback =>
    sendMessageToBackground('getAvailableDevices', callback)

export const sendViewNotification = (callback, params) =>
    sendMessageToBackground('sendViewNotification', callback, params)

export const getPlayMessage = (callback, params) =>
    sendMessageToBackground('getPlayMessage', callback, params)

export const openInApp = (callback, params) =>
    sendMessageToBackground('openInApp', callback, params)

export const getUserRank = (callback, params) =>
    sendMessageToBackground('getUserRank', callback, params)

export const getTopPlaybooksReports = (callback, params) =>
    sendMessageToBackground('getTopPlaybooksReports', callback, params)

export const getTopCreatorsReports = (callback, params) =>
    sendMessageToBackground('getTopCreatorsReports', callback, params)

export const updateContextMenu = (params: {
    contextMenus: string[]
    updateProps: any
}) => sendMessageToBackground('updateContextMenu', null, params)

// VIDEO PLAYER ACTIONS
export const changeVideoTime = (params: { seconds: number }) =>
    sendMessageToBackground('VIDEO_PLAYER/GO_TO', null, params)

export const changeVideoSpeed = (params: { speed: number }) =>
    sendMessageToBackground('VIDEO_PLAYER/CHANGE_SPEED', null, params)

export const getVideoRealTime = callback =>
    sendMessageToBackground('VIDEO_PLAYER/GET_ACTIVE_TIME', callback)

export const setPlaybookReview = (
    callback,
    params //  { playbookId, reaction, result }
) => sendMessageToBackground('setPlaybookReview', callback, params)

export const getPlaybookUserReview = (
    callback,
    params //  { playbookId }
) => sendMessageToBackground('getUserPlaybookReview', callback, params)

export const createTrackingLink = (
    callback: (error: Error | null) => void,
    params: { id: string; data: Record<string, any> }
) => sendMessageToBackground('createTrackingLink', callback, params)

export const getTrackingLinks = (
    callback: (links: Record<string, TrackingLinkType>) => void,
    params: { orgId: string; playbookId: string; uid: string }
) => sendMessageToBackground('getTrackingLinks', callback, params)

export const deleteTrackingLink = (
    callback: (error: Error | null) => void,
    params: { id: string }
) => sendMessageToBackground('deleteTrackingLink', callback, params)

export const redirectToOnboarding = () =>
    sendMessageToBackground('redirectToOnboarding', null)

// SENTRY
export const logSentryError = params =>
    sendMessageToBackground('logSentryError', null, params)

/* PERMANENT LISTENERS
 *
 * Use permanent listeners whenever you need to execute something in React on background script command.
 * Permanent listener's action name should be unique.
 * Therefore, each of the permanent listeners should only have 1 usage.
 *
 * Do not set up message listeners in React hooks,
 * because it may lead to having multiple callbacks for one action type.
 */

const setupPermanentListener = (
    action: string,
    callback: (response: any) => void
) => {
    callbacks[action] = callback
}

export const onRecordFromContext = (
    callback: (response: { selection: string }) => void
) => setupPermanentListener('CONTEXT_MENU/RECORD_WITH_TAG', callback)

export const onRecordFromKeyboard = (callback: () => void) =>
    setupPermanentListener('KEYBOARD/RECORD_WITH_TAG', callback)

export const onRecordFromWebapp = (callback: () => void) => {
    setupPermanentListener('WEBAPP/LAUNCH_EXT_RECORDER', callback)
}

export const onSearchFromContext = (
    callback: (response: { selection: string }) => void
) => setupPermanentListener('CONTEXT_MENU/TRIGGER_SEARCH', callback)

export const onSearchFromKeyboard = (callback: () => void) =>
    setupPermanentListener('KEYBOARD/TRIGGER_SEARCH', callback)

export const onTriggerVideoPlayer = (
    callback: (response: { playbookId: string; isPlaylist: boolean }) => void
) => setupPermanentListener('TRIGGER_VIDEO_PLAYER', callback)
