import {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react'
import clsx from 'clsx'
import { useDispatch, useSelector } from 'react-redux'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import {
    Avatar,
    Badge,
    Box,
    Button,
    Grid,
    makeStyles,
    Typography,
    Paper,
    MenuList,
    MenuItem,
    Tooltip,
} from '@material-ui/core'

import LinkIcon from '@material-ui/icons/Link'
import CloseIcon from '@material-ui/icons/Close'
import ShareIcon from '@material-ui/icons/Share'
import LaunchIcon from '@material-ui/icons/Launch'
import EditIcon from '@material-ui/icons/Edit'
import FastForwardIcon from '@material-ui/icons/FastForward'
import ViewIcon from '@material-ui/icons/Visibility'

import { AppContext } from 'app/AppProvider'

import { setActivePlaybook } from 'ducks/actions'
import { RootState } from 'ducks/rootReducer'

import {
    changeVideoSpeed,
    sendViewNotification,
    openExternalLink,
    logToMixpanel,
} from 'background/services'
import { playbookToMixpanelProps } from 'services/mixpanel'

import {
    VideoPlayer,
    PlaybookVisibility,
    Description,
    SpacedGroup,
} from 'UI/Components'

import config from 'config'

import { useBoolean, useRealtimeVideoTime } from 'hooks'
import { ExtensionOnly } from 'UI/Components/EnvComponents'

import { generatePlayerId } from 'services/video'
import { envLogicRouter, sdkOnly } from 'services/environmentService'
import { sendMessageToParentScript } from 'services/sdkServices'

const useStyles = makeStyles({
    videoWrapper: {
        position: 'relative',
        width: '100%',
        height: 0,
        paddingBottom: '56.25%', // when the aspect ratio of a video is 16:9. (9 is 56.25% of 16). If your video is 4:3, set it to 75%.
        background: 'black',
    },
    gray: {
        color: '#78909c',
    },
    avatar: {
        width: '16px',
        height: '16px',
    },
    controlBtn: {
        position: 'absolute',
        right: '8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgb(38 50 56 / 0.50);',
        minWidth: '40px !important',
        minHeight: '40px',
        borderRadius: '50%',
        padding: 0,
        opacity: 0,
        transform: 'translateX(30px)',
        transition: 'opacity 0.1s linear, transform 0.1s linear',
        '&:hover': {
            backgroundColor: 'rgb(38 50 56 / 80%)',
        },
        '& svg': {
            color: 'white',
        },
    },
    controlBtnVisible: {
        opacity: 1,
        transform: 'translateX(0)',
    },
    closeBtn: {
        top: '8px',
    },
    shareBtn: {
        top: '56px',
        transitionDelay: '0.1s !important',
    },
    transcriptBtn: {
        top: '104px',
        transitionDelay: '0.2s !important',
    },
    speedBtn: {
        transitionDelay: SDK_MODE ? '0.1s !important' : '0.3s !important',
        top: SDK_MODE ? '56px' : '150px',
    },
    copyBtn: {
        top: '196px',
        transitionDelay: '0.4s !important',
    },
    editBtn: {
        transitionDelay: '0.5s !important',
        top: '242px',
    },
    copyList: {
        top: '200px',
        position: 'absolute',
        right: '8px',
        zIndex: 2,
    },
    title: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    description: {
        width: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
    },
    fontSize: {
        fontSize: '13px',
    },
    disabledButton: {
        '&.Mui-disabled': {
            pointerEvents: 'auto',
            backgroundColor: '#ffffff80',
            '&:hover': {
                backgroundColor: '#ffffff80',
            },
        },
    },
    badge: {
        left: '-4px',
        top: '-4px',
        fontWeight: 600,
        fontSize: '10px',
    },
})

const formatDate = seconds =>
    new Date(seconds * 1000).toString().substring(4, 16)

export const VideoPanel = ({
    hoverState,
    showSidePanel,
    showShareDialog,
    playbook,
}) => {
    const dispatch = useDispatch()
    const copyDropdown = useBoolean()
    const realVideoTime = useRealtimeVideoTime()

    const videoRef: any = useRef()

    const { active: activeDrawer, playlistMode } = useContext(AppContext)

    const classes = useStyles()

    const { active, playbookList } = useSelector(
        (state: RootState) => state.playlist
    )

    const { user, roles } = useSelector((state: RootState) => state.user)
    const playlist = useSelector((state: RootState) => state.playbook) || {}
    const { uid } = user

    const mixpanelPayload = playbookToMixpanelProps(playbook)

    const toggleShareDialog = () => showShareDialog.toggle()

    const title =
        playbook?.title || playbook?.originalFileName?.replace(/\.mp4$/i, '')

    useEffect(() => {
        if (videoRef.current && videoRef?.current?.src !== playbook?.url) {
            videoRef.current.load()
        }
    }, [playbook.url])

    const isOwner = playbook?.creator_uid === user.uid

    // Send email notification to the video owner every time when someone watches playbook
    useEffect(() => {
        if (
            playbook &&
            Object.values(playbook).length > 0 &&
            user.uid &&
            roles.o &&
            !roles.s &&
            !isOwner
        ) {
            const viewKey = `playbookViews/${user.uid}/${playbook.id}`
            const isViewed = !!sessionStorage.getItem(viewKey)

            if (isViewed) {
                return
            }

            sendViewNotification(() => {}, {
                orgId: roles.o,
                uid: user.uid,
                playbookId: playbook.id,
            })
            logToMixpanel('videoPlay', playbookToMixpanelProps(playbook))

            sessionStorage.setItem(viewKey, Date.now().toString())
        }
    }, [playbook, roles.o, roles.s, user.uid, isOwner, playlist.id])

    const [videoSpeed, changeSpeed] = useState(1)
    const increaseVideoSpeed = useCallback(() => {
        changeSpeed(prevSpeed => {
            const newSpeed = prevSpeed === 2 ? 1 : prevSpeed + 0.25
            envLogicRouter(
                () => {
                    const playerId = generatePlayerId()

                    return ((
                        document.getElementById(playerId) as HTMLVideoElement
                    ).playbackRate = newSpeed)
                },
                () => changeVideoSpeed({ speed: newSpeed })
            )

            return newSpeed
        })
    }, [])

    const canEditPlaybook =
        isOwner ||
        roles.s ||
        (roles.a === 100 && roles.o === playbook.organizationId)

    const videoCopyLink = useMemo(() => {
        const path =
            (playlist.isPublic ? '/share/playlists/' : '/playbooks/playlist/') +
            playlist.id +
            '?origin=' +
            uid

        return config.firebase.authDomain + path
    }, [playlist])

    const onCopyLink = useCallback(() => {
        logToMixpanel('copyLink', {
            context: 'video-player',
            ...playbookToMixpanelProps(playlist),
        })
    }, [])

    if (Object.values(playbook).length === 0) {
        return null
    }

    return (
        <>
            <Box className={classes.videoWrapper}>
                <VideoPlayer
                    defaultSpeed={videoSpeed}
                    playbookURL={playbook.url}
                />
            </Box>

            <Button
                title="Close playbook"
                color="default"
                onClick={() => {
                    playlistMode.setFalse()
                    logToMixpanel('videoClose', mixpanelPayload)
                    sdkOnly(() => {
                        sendMessageToParentScript({
                            action: 'RESIZE_TO_NORMAL',
                        })
                        activeDrawer.setTrue()
                    })()
                }}
                size="small"
                className={clsx(
                    classes.controlBtn,
                    classes.closeBtn,
                    hoverState.isTrue && classes.controlBtnVisible
                )}
            >
                <CloseIcon />
            </Button>

            <ExtensionOnly>
                <Button
                    title="Share playlist"
                    color="default"
                    onClick={() => {
                        toggleShareDialog()
                    }}
                    size="small"
                    className={clsx(
                        classes.controlBtn,
                        classes.shareBtn,
                        hoverState.isTrue && classes.controlBtnVisible
                    )}
                    style={{ pointerEvents: 'auto' }}
                >
                    <ShareIcon />
                </Button>
            </ExtensionOnly>

            <ExtensionOnly>
                <Button
                    title="Toggle side panel"
                    color="default"
                    onClick={() => {
                        showSidePanel.toggle()
                    }}
                    size="small"
                    className={clsx(
                        classes.controlBtn,
                        classes.transcriptBtn,
                        hoverState.isTrue && classes.controlBtnVisible
                    )}
                >
                    <LaunchIcon />
                </Button>
            </ExtensionOnly>

            <Button
                title="Change video speed"
                color="default"
                onClick={increaseVideoSpeed}
                size="small"
                className={clsx(
                    classes.controlBtn,
                    classes.speedBtn,
                    hoverState.isTrue && classes.controlBtnVisible
                )}
            >
                <Badge
                    badgeContent={`${(
                        Math.round(videoSpeed * 100) / 100
                    ).toFixed(2)}x`}
                    color="primary"
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    classes={{
                        badge: classes.badge,
                    }}
                >
                    <FastForwardIcon />
                </Badge>
            </Button>

            <ExtensionOnly>
                <Button
                    title="Copy playbook link"
                    color="default"
                    size="small"
                    className={clsx(
                        classes.controlBtn,
                        classes.copyBtn,
                        hoverState.isTrue && classes.controlBtnVisible
                    )}
                    onClick={copyDropdown.toggle}
                >
                    <LinkIcon />
                </Button>

                {copyDropdown.isTrue && hoverState.isTrue && (
                    <Paper className={classes.copyList}>
                        <MenuList
                            id="split-button-menu"
                            onClick={copyDropdown.setFalse}
                            onMouseLeave={copyDropdown.setFalse}
                        >
                            <CopyToClipboard
                                text={videoCopyLink}
                                onCopy={onCopyLink}
                            >
                                <MenuItem>Copy Link</MenuItem>
                            </CopyToClipboard>
                            <CopyToClipboard
                                text={`${videoCopyLink}&active=${playbook.id}&t=${realVideoTime}`}
                                onCopy={onCopyLink}
                            >
                                <MenuItem>Copy Link at current time</MenuItem>
                            </CopyToClipboard>
                        </MenuList>
                    </Paper>
                )}

                {canEditPlaybook && (
                    <Button
                        title="Edit playbook"
                        color="default"
                        size="small"
                        className={clsx(
                            classes.controlBtn,
                            classes.editBtn,
                            hoverState.isTrue && classes.controlBtnVisible
                        )}
                        onClick={() => {
                            openExternalLink(null, {
                                url: `playbooks/playlist/${playlist.id}/edit`,
                            })
                        }}
                    >
                        <EditIcon />
                    </Button>
                )}
            </ExtensionOnly>

            <Box display="flex" justifyContent="center" mt={1}>
                <Button
                    classes={{ root: classes.disabledButton }}
                    size="small"
                    color="default"
                    disabled={active === 0}
                    onClick={() => dispatch(setActivePlaybook(active - 1))}
                >
                    Prev
                </Button>
                <Box ml={1} />
                <Button
                    classes={{ root: classes.disabledButton }}
                    size="small"
                    color="default"
                    disabled={active === playbookList.length - 1}
                    onClick={() => dispatch(setActivePlaybook(active + 1))}
                >
                    Next
                </Button>
            </Box>

            <Box style={{ flex: 1 }} px={2} py={1}>
                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        <Box mb={0.5}>
                            <Typography className={classes.title}>
                                <b>{title}</b>
                            </Typography>
                        </Box>

                        <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="space-between"
                        >
                            <Box mb={0.5} display="flex" flexDirection="row">
                                {playbook.applications?.length > 0 && (
                                    <Typography
                                        variant="body2"
                                        style={{ maxWidth: '200px' }}
                                    >
                                        {playbook.applications
                                            .map(it => it.applicationName)
                                            .join(', ')}
                                    </Typography>
                                )}
                                <Box ml={1}>
                                    <Typography
                                        variant="body2"
                                        className={clsx(
                                            classes.gray,
                                            classes.fontSize
                                        )}
                                    >
                                        {formatDate(playbook?.uploadedAt)}
                                    </Typography>
                                </Box>
                            </Box>

                            <ExtensionOnly>
                                <Box mb={0.5}>
                                    <Typography
                                        variant="body2"
                                        className={clsx(
                                            classes.gray,
                                            classes.fontSize
                                        )}
                                    >
                                        {playbook.tags
                                            ?.map(tag => `#${tag}`)
                                            .join('  ')}
                                    </Typography>
                                </Box>
                            </ExtensionOnly>
                        </Box>

                        {playbook?.views !== 0 && (
                            <Box mb={0.5}>
                                <SpacedGroup spacing={1}>
                                    <ViewIcon />
                                    <Typography
                                        variant="body2"
                                        className={clsx(
                                            classes.gray,
                                            classes.fontSize
                                        )}
                                    >
                                        Views: {playbook?.views}
                                    </Typography>
                                </SpacedGroup>
                            </Box>
                        )}
                        <Box mb={0.5}>
                            <Description description={playbook.description} />
                        </Box>
                    </Grid>
                    <Grid item xs={4}>
                        <Box mb={2}>
                            <Box
                                display="flex"
                                flexDirection="row"
                                alignItems="center"
                            >
                                <Box mr={0.5}>
                                    <Avatar
                                        className={classes.avatar}
                                        src={playbook?.userImage}
                                    />
                                </Box>
                                <Typography variant="body2">
                                    {playbook?.username}
                                </Typography>
                            </Box>
                        </Box>

                        <ExtensionOnly>
                            <PlaybookVisibility
                                visibility={playbook.visibility}
                            />
                        </ExtensionOnly>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}
