// UTILS
import { formatTime } from '../services/utils'
import { envLogicRouter, isExtension } from '../services/environmentService'
import * as MIXPANEL from '../background/mixpanel'
import firebase from '../firebase'
import { request } from './utils'

export const getPlaybooks = async params => {
    const { applicationId, tags, text, page } = params
    const logSearch = (event, data) =>
        MIXPANEL.logEvent(event, {
            'pb-appId': applicationId,
            tags,
            text,
            page,
            ...data,
        })

    try {
        const url = isExtension ? '/a/v1/search' : '/k/v1/getPlaybooks'

        const data = await request(url, 'POST', {
            ...params,
            ...(isExtension ? { source: 'extension' } : {}),
        })
        logSearch(
            text
                ? data.nbHits
                    ? 'search'
                    : 'search-no-results'
                : 'background_search',
            { hits: data.nbHits }
        )
        return data
    } catch (e) {
        console.error(e)
        logSearch('search_fail', { error: e })
        return null
    }
}

export const getPlaybook = async (playbookId: string) => {
    try {
        const url = isExtension ? '/a/v1/search' : '/k/v1/getPlaybook'

        const data = await request(url, 'POST', {
            playbookId,
            ...(isExtension ? { source: 'extension' } : {}),
        })
        return envLogicRouter(
            () => data,
            () => data?.playbooks?.[0] || {}
        )
    } catch (e) {
        console.error(e)
        return null
    }
}

const getPlaybookListSdk = async (playbookId: string) => {
    try {
        const data = await request('/k/v1/getPlaybookList', 'POST', {
            playbookId,
        })
        return data || []
    } catch (e) {
        console.error(e)
        return null
    }
}

const getPlaybookListExt = async (playbookIds: Array<string>) => {
    try {
        const responses: any = await Promise.allSettled(
            playbookIds?.map(id =>
                request('/a/v1/search', 'POST', {
                    playbookId: id,
                    ...(isExtension ? { source: 'extension' } : {}),
                })
            )
        )

        // filter only fulfilled promises
        return responses.flatMap(res => res?.value?.playbooks?.[0] || [])
    } catch (e) {
        console.error(e)
        return null
    }
}

export const getPlaybookList = async ({ playbookIds, playbookId }) => {
    try {
        return envLogicRouter(
            async () => await getPlaybookListSdk(playbookId),
            async () => await getPlaybookListExt(playbookIds)
        )
    } catch (e) {
        console.error(e)
        return null
    }
}

export const sharePlaybook = async payload => {
    try {
        return await request('/b/v1/playbookAction', 'POST', payload)
    } catch (e) {
        console.error(e)
        return null
    }
}

export const requestPlaybook = async payload => {
    try {
        return await request('/b/v1/request_playbook', 'POST', payload)
    } catch (e) {
        console.error(e)
        return null
    }
}

export const getTranscriptFile = async (transcriptUrl: string) => {
    try {
        const res = await fetch(transcriptUrl, {
            method: 'GET',
        }).then(res => res.text())
        const lines = res.split('\n')

        if (lines.length > 0 && lines[0] !== '0') return []

        const segmentsArr = [] as any
        for (let i = 0; i < lines.length; i += 2) {
            segmentsArr.push({
                time: formatTime(lines[i]),
                text: lines[i + 1],
                startTime: lines[i], // @TODO check whether we need startTime property as we don't have it in webapp
            })
        }
        return segmentsArr
    } catch (e) {
        console.error(e)
        return []
    }
}

export const sendViewNotification = async (
    msg,
    sendResponse?: (_data: any) => void
) => {
    try {
        const { orgId, uid, playbookId } = msg.params
        if (orgId) {
            const data = await firebase
                .database()
                .ref(`/playbookViews/${orgId}/${uid}/${playbookId}`)
                .set({
                    timestamp: firebase.database.ServerValue.TIMESTAMP,
                })
            return sendResponse?.(data)
        } else {
            const data = await request('/k/v1/sendViewNotification', 'POST', {
                playbookId,
            })
            return sendResponse?.(data)
        }
    } catch (e) {
        console.error(e)
        return sendResponse?.(null)
    }
}
