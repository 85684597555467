import { createStyles, Theme } from '@material-ui/core/styles'
import { SelectOptions } from 'types/select'

export const getDialogSharedStyles = (theme: Theme) =>
    createStyles({
        titleWrapper: {
            paggingBottom: '0',
        },
        title: {
            paddingBottom: '16px',
            fontSize: '24px',
            fontWeight: theme.typography.fontWeightBold as number,
        },
        closeButton: {
            position: 'absolute',
            right: 0,
            top: 0,
            padding: theme.spacing(0.5),
            margin: theme.spacing(0.5),
        },
        actions: {
            boxShadow: '0px -3px 5px #c7c7c7',
            padding: theme.spacing(3),
            justifyContent: 'center',
            flexDirection: 'row',
        },
        content: {
            display: 'flex',
            flexDirection: 'column',
            padding: `0 ${theme.spacing(6)}`,
            maxHeight: '520px',
            overflowY: 'auto',
            '& > *': {
                marginBottom: theme.spacing(3),
            },
        },
        fieldLabel: {
            color: '#7f8c9a',
        },
        recorder: {
            marginRight: theme.spacing(1),
            width: '14px',
            height: '14px',
            backgroundColor: theme.palette.primary.main,
            borderRadius: '50%',
        },
        panel: {
            display: 'flex',
        },
        btn: {
            marginRight: theme.spacing(0.5),
            '& p': {
                fontSize: '14px',
                fontWeight: '500',
            },
            '& svg': {
                color: '#090c10',
                fontSize: '18px',
            },
        },
        recordingCTA: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: theme.spacing(2),
            fontWeight: theme.typography.fontWeightBold as number,
        },
        iframe: {
            width: '100%',
            maxWidth: '450px',
            minHeight: '280px',
            maxHeight: '280px',
            border: 'none',
        },
    })

export const REQUEST_TYPE_KEY = {
    QUESTION: 'question',
    BUG: 'bug',
    FEATURE_REQUEST: 'feature_request',
    CONFIG_CHANGE: 'config_change',
    FEEDBACK: 'feedback',
}

export const REQUEST_TYPE = {
    question: 'Support Question',
    bug: 'Report a Problem',
    feature_request: 'New Feature',
    config_change: 'Configuration Change',
    feedback: 'Feedback',
}

export const REQUEST_TYPES = Object.entries(REQUEST_TYPE).map(
    ([value, label]) => ({ value, label })
)
