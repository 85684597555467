export const env = 'dev'

const FIREBASE_CONFIG = {
    apiKey: 'AIzaSyAECvQGtSbTa6P0o69l-fGgrMp2xLAeKlY',
    authDomain: 'stg.guidde.co',
    databaseURL: 'https://guidde-dev-staging-default-rtdb.firebaseio.com',
    projectId: 'guidde-dev-staging',
    storageBucket: 'guidde-dev-staging.appspot.com',
    messagingSenderId: '824103114815',
    appId: '1:824103114815:web:920ecf835920e36ead808a',
}
// prettier-ignore
const Config = Object.freeze({
    firebase: FIREBASE_CONFIG,
    idKey: '',
})
export default Config
