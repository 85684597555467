import short from 'short-uuid'

export const option = (value: any, label: string) => ({ value, label })

export const uniqueArray = (a: Array<any>): Array<any> =>
    [...new Set(a.map(o => JSON.stringify(o)))].map(s => JSON.parse(s))

export const round = number => Math.round(number * 100) / 100

export const uuid = () => short.generate()

export const formatTime = seconds => {
    const format = val => `0${Math.floor(val)}`.slice(-2)

    const hours = seconds / 3600
    const minutes = (seconds % 3600) / 60
    const secs = seconds % 60

    return [...(hours >= 1 ? [hours] : []), minutes, secs].map(format).join(':')
}

export const getSelectionText = () => window.getSelection?.()?.toString()

export const uiId = id => `ui-t-ext-${id}`

export const copyToClipboard = (text?: string) =>
    navigator.clipboard.writeText(text || '')
