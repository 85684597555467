export const DEV_EXT_ID = 'chkfdipjdldekkcflcflmaopfioieiem'

// Storage local variables
export const PREFFERRED_MIC_SETTING = 'STORAGE_LOCAL/PREFERRED_MIC'
export const REC_PANEL_VISIBILITY = 'STORAGE_LOCAL/REC_PANEL_VISIBILITY'
export const REC_CHAPTER_COUNT = 'STORAGE_LOCAL/REC_CHAPTER_COUNT'
export const LINK_REPLACER_FEATURE = 'STORAGE_LOCAL/LINK_REPLACER_FEATURE'
export const WEBCAM_SETTING = 'STORAGE_LOCAL/WEBCAM_SETTING'
export const SPACE_FILTER_STATE = 'STORAGE_LOCAL/SPACE_FILTER_STATE'

// Keyboard shortcuts
export const NEW_CHAPTER_COMMAND = 'new-chapter'
export const RECORD_COMMAND = 'record'
export const SEARCH_COMMAND = 'search'
export const DISCARD_RECORDING_COMMAND = 'discard'
export const TOGGLE_REC_PANEL_COMMAND = 'rec-panel-toggle' // Max amount of shortcuts is 4, so this one doesn't work
