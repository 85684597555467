import { getTenant, getToken } from '../firebase'
import { envLogicRouter, isLocal, isSDK } from '../services/environmentService'
import Config from '../config'

export const getHost = () =>
    envLogicRouter(
        () => (isLocal ? (window as any).localProxy : ''),
        () => `https://${Config.firebase.authDomain}`
    )

export const request = (
    url: string,
    method: 'GET' | 'POST' | 'PUT' | 'DELETE',
    body?: any
) =>
    getToken()
        .then(token =>
            fetch(`${getHost()}${url}`, {
                method,
                headers: {
                    'Content-Type': 'application/json',
                    authorization: `Bearer ${token}`,
                    ...(isSDK ? { 'X-Guidde-Tenant': getTenant() } : {}),
                },
                ...(body && { body: JSON.stringify(body) }),
            })
        )
        .then((res: Response) => {
            if (res.ok) {
                return res.json()
            } else {
                throw new Error(`API Error ${res?.status}: ${res?.url}`)
            }
        })
