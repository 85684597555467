import { useState, useEffect, useMemo } from 'react'
import { generatePlayerId } from 'services/video'

import { getVideoRealTime } from 'background/services'

export const useRealtimeVideoTime = () => {
    const [activeTime, setActiveTime] = useState(0)

    useEffect(() => {
        const interval = setInterval(() => {
            if (process.env.NODE_ENV === 'development' || SDK_MODE) {
                const el = document.querySelector<HTMLVideoElement>(
                    `#${generatePlayerId()}`
                )
                if (el) {
                    setActiveTime(el.currentTime)
                }
                return
            }
            getVideoRealTime(setActiveTime)
        }, 1500)

        return () => {
            if (interval) {
                clearInterval(interval)
            }
        }
    }, [])

    return useMemo((): number => activeTime, [activeTime])
}
