import { QuerySnapshot } from '@firebase/firestore-types'
import { logEvent, registerMixpanelSuperProps } from '../background/mixpanel'
import { TagCondition } from '../services/tagsParser'
import {
    encodeCreators,
    encodeTopPlaybooks,
} from '../UI/Pages/insights/encoders'
import firebase from './initFirebase'
import { subscribeForNotifications } from './notifications'
import { extensionOnly } from '../services/environmentService'
import { isEmpty, isEqual } from 'lodash'
import { request } from '../api/utils'

export type User = { user: any; roles: any; userInfo?: any } | null
export type UserCallback = (user: User | null) => void

let firebaseUser: User = null
let tagsParsingAPI: { [key: string]: TagCondition[] } = {}

export const getToken = () =>
    firebase.auth().currentUser?.getIdToken() || Promise.resolve('')

const setUser = (user: User, callback: UserCallback) => {
    firebaseUser = user
    callback(user)
}

const subscribeToTagsParsingAPI = () => {
    firebase
        .database()
        .ref('extConfig')
        .on('value', res => {
            tagsParsingAPI = res.val()
        })
}

export const getTagsParsingAPI = () => {
    return tagsParsingAPI
}

export const registerToFirebaseAuth = (callback: UserCallback) => {
    let notifObserver: any = null

    firebase.auth().onAuthStateChanged(user => {
        if (!user) {
            console.log('user logged out')
            setUser(null, callback)
            registerMixpanelSuperProps()
            tagsParsingAPI = {}
            notifObserver && notifObserver()
        }
    })

    firebase.auth().onIdTokenChanged(user => {
        if (!user) {
            // onAuthStateChanged handles it
            return null
        }

        user.getIdTokenResult().then(idTokenResult => {
            const extUser: User = {
                user: user,
                roles: idTokenResult.claims.roles,
            }

            // not ready yet
            if (!idTokenResult.claims.roles) return null

            setUser(extUser, callback)
            registerMixpanelSuperProps(extUser)

            subscribeToTagsParsingAPI()
            notifObserver = subscribeForNotifications(user.uid)
        })
    })
}

export const registerSDKToFirebaseAuth = (
    tenant: string,
    apiKey: string,
    session: string,
    userInfo: any,
    callback: UserCallback
) => {
    firebase.auth().tenantId = tenant

    if (!userInfo || isEmpty(userInfo)) userInfo = undefined

    firebase.auth().onAuthStateChanged(user => {
        if (!user) {
            request('/i/v1/auth', 'POST', {
                apiKey,
                session,
                user: { userInfo },
            }).then(res => {
                loginFirebase(res?.token)
            })
        }
    })

    firebase.auth().onIdTokenChanged(user => {
        if (!user) {
            // onAuthStateChanged handles it
            return null
        }

        user.getIdTokenResult().then(idTokenResult => {
            const sdkUser: User = {
                user: user,
                roles: null,
                userInfo: idTokenResult.claims.userInfo,
            }
            if (!isEqual(userInfo, sdkUser.userInfo)) {
                return firebase
                    .auth()
                    .signOut()
                    .catch(_ => {})
            }

            setUser(sdkUser, callback)
        })
    })
}

export const getUser = () => {
    return firebaseUser
}

export const getOrgUsers = async (orgId: string) => {
    const users: Array<any> = []
    await firebase
        .firestore()
        .collection(`usersPerOrg/${orgId}/users/`)
        .get()
        .then((querySnapshot: QuerySnapshot) => {
            querySnapshot.forEach(doc => {
                users.push(doc.data())
            })
        })
        .catch(() => [])
    return users
}

export const logoutFirebase = () => {
    return new Promise(resolve => {
        firebase
            .auth()
            .signOut()
            .then(() => {
                logEvent('logout')
                resolve(true)
            })
            .catch(error => {
                console.error('firebase logout', error)
                resolve(false)
            })
    })
}

export const getTenant = () => firebase.auth().tenantId

export const loginFirebase = (customToken, successCallback = () => {}) =>
    customToken
        ? new Promise(resolve => {
              firebase
                  .auth()
                  .signInWithCustomToken(customToken)
                  .then(res => {
                      resolve(res.user)
                      successCallback()
                      extensionOnly(() => logEvent('login'))
                  })
                  .catch(error => {
                      console.error(
                          'GUIDDE SDK:',
                          error?.message || 'Login error'
                      )
                      resolve(null)
                  })
          })
        : logoutFirebase()

export const getOrganization = (orgId: string) => {
    return firebase
        .firestore()
        .collection('organizations')
        .doc(orgId)
        .get()
        .then(snap => {
            const data: any = snap.data()
            return data || []
        })
        .catch(() => ({ name: '', domains: [] }))
}

export const getFirebaseApps = () => {
    const appIds: Array<string> = []
    const allApps: Array<any> = []

    const orgId = firebaseUser?.roles?.o
    if (!orgId) return Promise.resolve(null)

    return firebase
        .firestore()
        .collection(`usersPerOrg/${orgId}/applications`)
        .get()
        .then((querySnapshot: QuerySnapshot) => {
            querySnapshot.forEach(doc => {
                appIds.push(doc.id)
            })
        })
        .then(() =>
            firebase
                .firestore()
                .collection(`applications`)
                .get()
                .then((querySnapshot: QuerySnapshot) => {
                    querySnapshot.forEach(doc => {
                        allApps.push(doc.data())
                    })
                })
        )
        .then(() => allApps.filter(app => appIds.includes(app.id)))
}

export const getUserSpaces = () => {
    const uid = firebaseUser?.user.uid

    return firebase
        .firestore()
        .collection('spaces')
        .where(`members.${uid}.uid`, '==', uid)
        .get()
        .then(snap => {
            const result: { [id: string]: any } = {}
            snap.forEach(doc => {
                const space = doc.data()
                result[space.id] = space
            })
            return result
        })
        .catch(console.error)
}

export const getFirebaseScheduleUrl = () => {
    const orgId = firebaseUser?.roles?.o
    if (!orgId) return Promise.resolve(null)

    return firebase
        .firestore()
        .doc(`organizations/${orgId}`)
        .get()
        .then(snap => snap.data()?.scheduleURL)
}

export const sendViewNotification = (orgId, uid, playbookId) => {
    return firebase
        .database()
        .ref(`/playbookViews/${orgId}/${uid}/${playbookId}`)
        .set({
            timestamp: firebase.database.ServerValue.TIMESTAMP,
        })
}

export const getTopPlaybooks = ({ orgId }) => {
    const reports: Array<any> = []
    return firebase
        .firestore()
        .collection(`analytics/${orgId}/reports`)
        .where('name', 'in', [
            'topPlaybooksReport-7-teams',
            'topPlaybooksReport-7-customers',
        ])
        .get()
        .then(async snap => {
            snap.forEach(e => {
                const resReport = e.data()
                const data = JSON.parse(resReport.data)
                reports.push({ name: resReport.name, data })
            })
            return reports
        })
        .then(reports => {
            return reports.map(singleReport => ({
                name: singleReport.name,
                data: encodeTopPlaybooks(singleReport.data).map(
                    ({ name: pbID, value: views }) => {
                        const playbooksRef = () =>
                            firebase.firestore().collection('playbooks')
                        return playbooksRef()
                            .doc(pbID)
                            .get()
                            .then(res => ({
                                ...res.data(),
                                pbID: pbID,
                                value: views,
                            }))
                            .catch(console.error)
                    }
                ),
            }))
        })
        .then(reportsWithPromises => {
            const promises = reportsWithPromises.map(r => Promise.all(r.data))
            return Promise.all(promises).then(reportsData =>
                reportsData.map((r, ind) => ({
                    name: reportsWithPromises[ind].name,
                    data: r.sort((a: any, b: any) => b.value - a.value),
                }))
            )
        })
}

export const getUserById = (orgId, uid, value) =>
    firebase
        .firestore()
        .collection(`usersPerOrg/${orgId}/users`)
        .where('uid', '==', uid)
        .limit(1)
        .get()
        .then(res => ({ ...res.docs?.[0]?.data(), value }))
        .catch(console.log)

export const getTopCreators = ({ orgId }) => {
    const reports: Array<any> = []

    return firebase
        .firestore()
        .collection(`analytics/${orgId}/reports`)
        .where('name', 'in', [
            'topCreatorsReport-7-teams',
            'topCreatorsReport-7-customers',
        ])
        .get()
        .then(async snap => {
            await snap.forEach(e => {
                const resReport = e.data()
                const data = JSON.parse(resReport.data)
                reports.push({
                    name: resReport.name,
                    data: encodeCreators(orgId, data),
                })
            })
            return reports
        })
        .then(repNoUsers => {
            return repNoUsers.map(r => ({
                ...r,
                data: r.data.map(u =>
                    getUserById(orgId, u.uid || u.email, u.value)
                ),
            }))
        })
        .then(reportsWithPromises => {
            const promises = reportsWithPromises.map(r => Promise.all(r.data))
            return Promise.all(promises).then(reportsData => {
                return reportsData.map((r, ind) => ({
                    name: reportsWithPromises[ind].name,
                    data: r.sort((a: any, b: any) => b.value - a.value),
                }))
            })
        })
}

export default firebase
